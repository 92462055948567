import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const bukavuiii = () => (
  <Layout>
    <SEO title="Dernières oeillades - Bukavu III" />
    <h3 className='underline-title'>Bukavu III</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Région enclavée aux colosses montagnes tondues<br />
        Ville-Bushi aux nombreuses pépinières pour Bami<br />
        Je t'ai aimé, je t'aime et t'aimerai encore<br />
      </p>
      <p>
        Qadèsh hébraïque<br />
        Lieu de longue halte<br />
        Oasis aux quatres sources<br />
        D'où je nomadisais de muse en muse<br />
      </p>
      <p>
        Siège de la déesse lyrique<br />
        Je vais te quitter<br />
        Je dois te quitter<br />
        Le pressant appel de la chaleur torride <br />
        <span className="par-starting"></span>me subjugue!<br />
      </p>
      <p>
        La-bàs ma tête crachera d'autres mots<br />
        D'autre langage volcanique sans volcan<br />
      </p>
      <p>
        Jardin édénique aux verbes tempérés<br />
        Je n'aimerais pas te quitter<br />
        Mais je dois te quitter<br />
        Pour d'autre vocable de feu!<br />
      </p>
      <p>
        Rappelle-toi ville de renom<br />
        Je suis formé à la dure école de la chaleur<br />
        Je ne combats mieux que là où il darde<br />
        Je dois rentrer pour jauger les volts accumulés<br />
        Je ne te demande qu'une chose Bukavu<br />
      </p>
      <p>
        Munis-moi du transfo survolteur<br />
        Que ma résistance subisse moins de chute de tension<br />
        Que mon appareil soit régulateur<br />
        Rectifiant les effets de la chute et de survolt de tension.<br />
      </p>
    </div>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default bukavuiii
